<script setup>
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import ScopeItemRow from "../../components/ScopeItemRow.vue";
import { scopeItemsDefault, scopeItemUnitTypes, scopeSections, toCurrency } from "../../assets/js/helpers";
import UnsavedChangesAlert from "./UnsavedChangesAlert.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import setTooltip from "@/assets/js/tooltip.js";
import PDFVuer from "./PDFVuer.vue";

// Props
const props = defineProps({
    propertyId: {
        type: String,
        required: true
    },
    propertyMetadata: {
        type: Object,
    },
    contractId: {
        type: String,
        required: true
    },
    address: {
        type: String,
        required: true
    },
    loadContract: {
        type: Function,
    },
    contractStatus: {
        type: Number
    },
    scopeFileUrl: {
        type: String
    },
});

// Reactive references
const loading = ref(false);
const scopeObj = ref({});
const unsavedChanges = ref(false);
const showPdf = ref(false);
const currentSection = ref('');

// Vuex store and toast notifications
const store = useStore();
const toast = useToast();

// API endpoint and authorization headers
const apiEndpoint = store.state.apiEndpoint;
const token = localStorage.getItem("token");
const headers = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}

// Disable editing if contract status is not 1
const disabledEdit = props.contractStatus !== 1;

// Default item structure
const defaultItem = {
    item: "",
    qty: 0,
    unit: "",
    cost: 0.00,
    item_code: ""
};

// Fetch scope data on component mount
onMounted(async () => {
    await getScope();
    setTooltip();
});

// Watch for changes in propertyId to refresh scope data
watch(
    () => props.propertyId,
    async (newVal) => {
        if (newVal) {
            await getScope();
        }
    }
);

/**
 * Fetch the scope data for the property
 */
const getScope = async () => {
    loading.value = true;
    try {
        const response = await axios.get(`${apiEndpoint}/property/get-scope/${props.propertyId}`, headers);
        if (Object.keys(response.data.scope).length !== 0) {
            // Assign scope
            scopeObj.value = response.data.scope;

            // Recalculate totals for all sections after loading
            Object.keys(scopeObj.value).forEach(sectionId => {
                calculateSectionTotal(sectionId);
            });
        } else {
            // If no scope found then set to default
            scopeObj.value = scopeItemsDefault;
        }
    } catch (error) {
        toast.error("Failed to retrieve scope data");
    } finally {
        loading.value = false;
    }
};

/**
 * Save the current scope data
 */
const handleSave = async () => {
    loading.value = true;
    try {
        await axios.put(`${apiEndpoint}/property/save-scope/${props.propertyId}/`, {
            scope: scopeObj.value,
        }, headers);
        toast.success("Property scope saved successfully");
        unsavedChanges.value = false;
        await getScope();
        await props.loadContract();
    } catch (error) {
        console.error(error);
        toast.error("An error occurred while saving property scope");
    } finally {
        loading.value = false;
    }
};

/**
 * Add a new item to a section
 * @param {String} sectionId - The ID of the section
 */
const handleAddItem = (sectionId) => {
    if (scopeObj.value[sectionId]) {
        scopeObj.value[sectionId]?.items.push({ ...defaultItem });
    } else {
        scopeObj.value[sectionId] = {
            items: [{ ...defaultItem }]
        };
    }
    unsavedChanges.value = true;
};

/**
 * Handle changes to a cell in the scope table
 * @param {String} sectionId - The ID of the section
 * @param {Number} itemIndex - The index of the item
 * @param {String} key - The key of the item property
 * @param {String|Number} value - The new value for the item property
 */
const handleCellChange = (sectionId, itemIndex, key, value) => {
    // Update the specific cell value
    scopeObj.value[sectionId].items[itemIndex][key] = key === 'cost' ? Number(value).toFixed(2) : value;

    // If the changed cell is qty or cost, recalculate section total
    if (key === 'cost') {
        calculateSectionTotal(sectionId);
    }
    unsavedChanges.value = true;
};

/**
 * Delete an item from a section
 * @param {String} sectionId - The ID of the section
 * @param {Number} itemIndex - The index of the item
 */
const handleDeleteItem = (sectionId, itemIndex) => {
    scopeObj.value[sectionId].items.splice(itemIndex, 1);
    calculateSectionTotal(sectionId);
    unsavedChanges.value = true;
};

/**
 * Calculate the total cost for a section
 * @param {String} sectionId - The ID of the section
 */
const calculateSectionTotal = (sectionId) => {
    if (!scopeObj.value[sectionId] || !scopeObj.value[sectionId].items) {
        scopeObj.value[sectionId] = {
            items: [],
            total: 0.00
        };
        return;
    } else if (!scopeObj.value[sectionId].total) {
        scopeObj.value[sectionId].total = 0.00;
    }

    // Calculate total by multiplying qty and cost for each item and summing
    const total = scopeObj.value[sectionId].items.reduce((acc, item) => {
        const cost = Number(item.cost) || 0; // Ensure cost is a number
        return acc + cost;
    }, 0);

    // Update the total for this section
    scopeObj.value[sectionId].total = Number(total.toFixed(2));
};

/**
 * Reset the scope data to default
 */
const handleReset = () => {
    scopeObj.value = scopeItemsDefault;
    unsavedChanges.value = true;
};

/**
 * Download the scope as a PDF
 */
const handleDownloadScopePDF = async () => {
    loading.value = true;
    try {
        const response = await axios.get(`${store.state.apiEndpoint}/property/download_scope/${props.propertyId}/`, {
            ...headers,
            responseType: 'arraybuffer',
        });

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create an Object URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `scope_${props.address}.pdf`);
        document.body.appendChild(link);
        link.click();

        // Clean up the Object URL
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error(error);
        toast.error("An error occurred while downloading Schedule 2");
    } finally {
        loading.value = false;
    }
};

/**
 * Set the current section for PDF viewing
 * @param {String} sectId - The ID of the section
 */
const setSection = (sectId) => {
    const section = props.propertyMetadata?.sections[sectId];
    console.log("Section:", section);
    currentSection.value = section;
    showPdf.value = true;
};

</script>

<template>
    <LoadingSpinner v-if="loading" message="Loading..." :fullScreen="true" />
    <UnsavedChangesAlert v-if="unsavedChanges">
        You have unsaved changes, please <a class="btn btn-link text-info p-0 m-0 px-2 mx-2 bg-light"
            @click="handleSave">SAVE NOW</a>
        to avoid losing them.
    </UnsavedChangesAlert>
    <div>
        <div class="scope-header d-flex justify-content-between">
            <div>
                <div class="scope-header-div">
                    <h1 class="scope-h1 col-md-12">Capital Improvement Scope of Work: Dwelling</h1>
                </div>
                <h5 class="fwt-bold scope-h5">Work Detail by Section: GST Exclusive</h5>
            </div>

            <div class="mb-3">

                <button data-bs-toggle="tooltip" title="Download PDF of this property's Schedule 2"
                @click="handleDownloadScopePDF"
                    data-bs-placement="top" class="btn btn-link text-info"><i
                        class="fas fa-file-pdf me-2"></i>PDF</button>

                <button 
                @click="handleReset" data-bs-toggle="tooltip" title="Clicking this will reset the scope tables"
                    data-bs-placement="top" class="btn btn-link text-info" v-if="!disabledEdit"><i
                        class="fas fa-sync me-2"></i>Reset</button>

                <button class="btn bg-gradient-info" v-if="!disabledEdit"  @click="handleSave"><i class="fas fa-save me-2"></i>Save</button>


            </div>
        </div>
        <div class="property-scope">
            <div class="row col-md-12">
                <p class="p-0 mb-0 fwt-bold">Works Contract ID:</p>
                <span class="gray-cell col-6 ms-0 ps-0">{{ contractId }}</span>
                <div class="pt-3"></div>
                <p class="p-0 mb-0 fwt-bold">Address:</p>
                <span class="gray-cell col-6 ms-0 ps-0">{{ address }}</span>
            </div>
        </div>
        <div class="scope-table my-3 row">
            <div v-for="(scopeSect, key) in scopeSections" :key="scopeSect.id">
                <div class="scope-section-row row col-12 mt-3">
                    <div class="scope-section-code border border-dark border-1 col-1 text-center fwt-bold">
                        {{ scopeSect.code }}
                    </div>

                    <div class="scope-section-code border border-dark border-1 col-5 fwt-bold gray-cell p-0 ">
                        {{ scopeSect.title }}
                    </div>
                    <div class="col-1 p-0 ms-2" >
                        <button v-if="propertyMetadata?.sections?.[scopeSect.id]?.page"
                        data-bs-toggle="tooltip"
                            title="Click to view section in the Scope Document"
                            class="btn btn-sm btn-link p-0 m-0" @click="setSection(scopeSect.id)">
                            <i class="fas fa-eye"></i>
                        </button>
                    </div>
                </div>
                <div class="scope-section-row row col-12 mt-3 d-flex align-items-center">

                    <ScopeItemRow v-for="(colSize, index) in ['1', '4', '1', '1', '2']" :key="index" :colSize="colSize"
                        :textCenter="index === 0">
                        <span v-if="index === 0">Code</span>
                        <span v-else-if="index === 1">Description &/or Location:</span>
                        <span v-else-if="index === 2">Unit</span>
                        <span v-else-if="index === 3">Qty</span>
                        <span v-else>Builders Price</span>
                    </ScopeItemRow>
                    <div class="col-2 fwt-bold">
                        <span v-if="scopeSect.items">Related Schedule 2 Subitem </span>
                    </div>
                </div>
                <div class="scope-section-row row col-12"
                    v-for="(scopeItem, scopeItemIndex) in scopeObj[scopeSect.id]?.items" :key="scopeItem.id"
                    :class="scopeItem.item_code?.toLowerCase() === 'eep' ? 'is-eep' : ''">
                    <ScopeItemRow colSize="1" :textCenter="true">
                        <input :value="scopeItem.item_code" class="form-control" :disabled="disabledEdit"
                            :id="`item-code-${scopeItemIndex}-code-for${scopeItem.id}`"
                            @blur="(event) => handleCellChange(scopeSect.id, scopeItemIndex, 'item_code', event.target.value)" />
                    </ScopeItemRow>
                    <ScopeItemRow colSize="4">
                        <textarea class="form-control" rows="2" :id="`item-${scopeItemIndex}-for${scopeItem.id}`" :disabled="disabledEdit"
                            @blur="(event) => handleCellChange(scopeSect.id, scopeItemIndex, 'item', event.target.value)">{{ scopeItem.item }}</textarea>
                    </ScopeItemRow>
                    <ScopeItemRow colSize="1">
                        <select class="form-control" :id="`unit-${scopeItemIndex}-for${scopeItem.id}`"
                            :value="scopeItem.unit?.toLowerCase()" :disabled="disabledEdit"
                            @change="(event) => handleCellChange(scopeSect.id, scopeItemIndex, 'unit', event.target.value)">
                            <option v-for="unit in scopeItemUnitTypes" :value="unit.value" :key="unit.value">{{
                                unit.label
                                }}</option>
                        </select>
                    </ScopeItemRow>
                    <ScopeItemRow colSize="1">
                        <input type="number" :value="scopeItem.qty" class="form-control"
                            :id="`qty-${scopeItemIndex}-for${scopeItem.id}`" :disabled="disabledEdit"
                            @blur="(event) => handleCellChange(scopeSect.id, scopeItemIndex, 'qty', event.target.value)" />
                    </ScopeItemRow>
                    <ScopeItemRow colSize="2">
                        <div class="input-group">
                            <span class="input-group-text bg-light">$</span>
                            <input class="form-control ps-2" type="number" placeholder="$0.00" :value="scopeItem.cost"
                                :id="`cost-${scopeItemIndex}-for${scopeItem.id}`" :disabled="disabledEdit"
                                @change="(event) => handleCellChange(scopeSect.id, scopeItemIndex, 'cost', event.target.value)"
                                step="0.01" />
                        </div>
                    </ScopeItemRow>

                    <div class="col-md-2">
                        <div v-if="scopeSect.items" class="mt-3" >
                            <select class="form-control" 
                            :id="`schedule2-subitem-${scopeItemIndex}-for${scopeItem.id}`" :disabled="disabledEdit"
                                @blur="(event) => handleCellChange(scopeSect.id, scopeItemIndex, 'subitem', event.target.value)"
                                :value="scopeItem.subitem" >
                                <option value="">N/A</option>
                                <option v-for="item in scopeSect.items" :value="item.id" :key="item.id">
                                    {{ item.title }} {{ item.code=='EESHP' ? '(EESHP)' : '' }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-1 d-flex justify-content-end " v-if="!disabledEdit">
                        <button class="btn btn-link text-danger"
                            @click="handleDeleteItem(scopeSect.id, scopeItemIndex)">
                            <i class="fas fa-trash fs-5"></i>
                        </button>
                    </div>
                </div>
                <div class="scope-section-row row col-12" :id="`total-for${scopeSect.id}`">
                    <div class="offset-7 col-2 border border-dark border-1 gray-cell text-end">
                        {{ toCurrency(scopeObj[scopeSect.id]?.total) }}
                    </div>
                </div>
                <div class="scope-section-row row col-12 mt-3">
                    <div class=" d-flex justify-content-end" v-if="!disabledEdit">
                        <button class="btn bg-gradient-primary text-light " @click="handleAddItem(scopeSect.id)">
                            <i class="fas fa-plus fs-5"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="scopeFileUrl && showPdf" class="pdf-viewer pdf-fixed-bottom">
        <PDFVuer :pdfUrl="scopeFileUrl" :pageNumber="currentSection?.page" :hidePdf="() => showPdf = false"
            :highlightText="currentSection?.schedule2_title" />
    </div>

</template>
