<script setup>
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import { ref, watch } from 'vue';
import '@tato30/vue-pdf/style.css'

const props = defineProps({
  pdfUrl: {
    type: String,
    required: true
  },
  pageNumber: {
    type: Number,
    default: 1
  },
  hidePdf: {
    type: Function,
  },
  highlightText: {
    type: String,
    default: ''
  }
});
const { pdf } = usePDF(props.pdfUrl)
const pageNum = ref(props.pageNumber)
const highlights = ref(props.highlightText)

watch(() => props.pageNumber, (val) => {
  pageNum.value = val
})

watch(() => props.highlightText, (val) => {
  highlights.value = val
})


function nextPage() {
  pageNum.value++
}

function previousPage() {
  if (pageNum.value > 1) pageNum.value--
}

</script>

<template>
  <div class="sticky-top d-flex justify-content-between" style="top:0;z-index:1000" >
    <div class="col-2 d-flex gap-2 align-items-center" >
      <a  href="javascript:void(0)" @click="previousPage"><i class="fas fa-chevron-left p-4" ></i></a>
      <input type="number" v-model="pageNum" min="1" class="form-control w-25 p-0 m-0 h-50 text-center" />
      <a  href="javascript:void(0)" @click="nextPage"><i class="fas fa-chevron-right p-4" ></i></a>
    </div>
    <a  href="javascript:void(0)" @click="hidePdf"><i class="fas fa-times p-4" ></i></a>
  </div>
  <div class="d-flex justify-content-center" >
    <VuePDF :pdf="pdf" :page="pageNum" :scale="2.5" text-layer :highlight-text="highlights">
      <div>
      Loading...
    </div>
    </VuePDF>
  </div>
</template>
