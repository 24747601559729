<script setup>
import axios from 'axios';
import moment from 'moment';
import { ref, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { defaultPageSizes, getStatusColor } from '../../assets/js/helpers';
import LoadingSpinner from '../../components/LoadingSpinner.vue';

const store = useStore();
const apiEndpoint = store.state.apiEndpoint;
const contracts = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const itemsPerPage = ref(10);
const loading = ref(false);

// Sorting state
const sortField = ref('');
const sortOrder = ref('asc');

// Available page size options
const pageSizeOptions = defaultPageSizes

const token = localStorage.getItem('token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
};

async function fetchContracts(page = 1) {
    try {
        loading.value = true;
        const sortQuery = sortField.value ? `&sort_by=${sortField.value}&sort_order=${sortOrder.value}` : '';
        const response = await axios.get(
            `${apiEndpoint}/contracts/?page=${page}&items_per_page=${itemsPerPage.value}${sortQuery}`, 
            config
        );
        contracts.value = response.data.results;
        totalItems.value = response.data.count;
        totalPages.value = response.data.total_pages;
        currentPage.value = response.data.current_page;
    } catch (error) {
        console.error(error);
    } finally {
        loading.value = false;
    }
}

const handleSort = (field) => {
    if (sortField.value === field) {
        // Toggle order if clicking the same field
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    } else {
        // New field, default to ascending
        sortField.value = field;
        sortOrder.value = 'asc';
    }
    fetchContracts(currentPage.value);
};

const getSortIcon = (field) => {
    if (sortField.value !== field) return 'fa-sort';
    return sortOrder.value === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
};

const changePage = async (page) => {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        await fetchContracts(page);
    }
};

const changePageSize = async (event) => {
    itemsPerPage.value = parseInt(event.target.value);
    currentPage.value = 1;
    await fetchContracts(1);
};

onMounted(() => {
    fetchContracts();
});

onBeforeMount(async () => {
    store.state.showNavbar = true;
    store.state.showFooter = false;
    store.state.hideConfigButton = true;
});
</script>

<template>
    <div class="card">
        <div class="card-header pb-0 d-flex justify-content-between align-items-center">
            <h6>Contracts</h6>
            <div class="d-flex align-items-center">
                <label for="pageSize" class="me-3">Items per page:</label>
                <select 
                    id="pageSize" 
                    class="form-select pe-4 form-select-sm" 
                    :value="itemsPerPage"
                    @change="changePageSize"
                    style="width: auto"
                >
                    <option 
                        v-for="option in pageSizeOptions" 
                        :key="option.value" 
                        :value="option.value"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </div>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0" v-if="contracts.length > 0">
                <table class="table align-items-center mb-0" >
                    <thead>
                        <tr>
                            <th 
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                @click="handleSort('title')"
                            >
                                Title
                                <i class="fas" :class="getSortIcon('title')" />
                            </th>
                            <th 
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 cursor-pointer"
                                @click="handleSort('properties')"
                            >
                                Properties
                                <i class="fas" :class="getSortIcon('properties')" />
                            </th>
                            <th 
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                @click="handleSort('status')"
                            >
                                Status
                                <i class="fas" :class="getSortIcon('status')" />
                            </th>
                            <th 
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                @click="handleSort('contract_number_id')"
                            >
                                Contract Number ID
                                <i class="fas" :class="getSortIcon('contract_number_id')" />
                            </th>
                            <th 
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                @click="handleSort('user')"
                            >
                                Created By
                                <i class="fas" :class="getSortIcon('user')" />
                            </th>
                            <th 
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                @click="handleSort('created_at')"
                            >
                                Creation Date
                                <i class="fas" :class="getSortIcon('created_at')" />
                            </th>
                            <th 
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 cursor-pointer"
                                @click="handleSort('start_date')"
                            >
                                Contract Starting Date
                                <i class="fas" :class="getSortIcon('start_date')" />
                            </th>
                            <th class="text-secondary opacity-7"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Rest of the table body remains the same -->
                        <tr v-for="contract in contracts" :key="contract.id">
                            <td>
                                <router-link :to="`/contract/${contract.idslug}`" class="text-primary font-weight-bold text-xs">
                                    <i class="fas fa-file-pdf ms-1 me-2"></i> {{ contract.title }}
                                </router-link>
                                <span class="text-secondary text-xs font-weight-bold"></span>
                            </td>
                            <td>
                                <p class="text-xs font-weight-bold mb-0">{{ contract.properties }}</p>
                            </td>
                            <td class="align-middle text-center text-sm">
                                <span class="badge badge-sm" :class="[getStatusColor(contract.get_status_display)]">
                                    {{ contract.get_status_display }}
                                </span>
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold">{{ contract.contract_number_id }}</span>
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold">{{ contract.user }}</span>
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold">{{ moment(contract.created_at).format('DD/MM/YYYY') }}</span>
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold">{{ moment(contract.start_date).format('DD/MM/YYYY') }}</span>
                            </td>
                            <td class="align-middle">
                                <router-link :to="`/contract/${contract.idslug}`" class="text-primary font-weight-bold text-xs"
                                    data-toggle="tooltip" data-original-title="Go to contract">
                                    <i class="fas fa-arrow-right ms-1"></i>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- Loading indicator and pagination remain the same -->
                <LoadingSpinner 
                    v-if="loading"
                    message="Loading..."
                />

                <div v-if="!loading && totalPages > 1" class="d-flex justify-content-center mt-4">
                    <nav aria-label="Page navigation">
                        <ul class="pagination pagination-primary">
                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">
                                    <i class="fas fa-angle-left"></i>
                                </a>
                            </li>
                            <li v-for="page in totalPages" 
                                :key="page" 
                                class="page-item"
                                :class="{ active: currentPage === page }">
                                <a class="page-link" href="#" @click.prevent="changePage(page)">
                                    {{ page }}
                                </a>
                            </li>
                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">
                                    <i class="fas fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div class="text-center mt-3 text-sm text-secondary">
                    Showing {{ contracts.length }} of {{ totalItems }} items
                    
                </div>
                
            </div>
            <div class="text-center mt-3 text-sm text-secondary">
                    <div v-if="contracts.length===0" class="text-lg my-3">
                        No contracts found. <a class="text-primary" href="/new-contract" >Create one now</a>
                    </div>
                </div>
        </div>
    </div>
</template>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

th i.fas {
    margin-left: 0.5rem;
    font-size: 0.75rem;
}
</style>