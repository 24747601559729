<script setup>
import { ref } from "vue";
import PropertySchedule2 from "./PropertySchedule2.vue";
import PropertyScope from "./PropertyScope.vue";

// Props
const props = defineProps({
    property: {
        type: Object,
        required: true
    },
    loadContract: {
        type: Function,
    },

    selectedId: {
        type: Number,
    },
    contract:{
        type: Object
    }

});

const currentTab = ref('scope')

</script>

<template>
    <div class="row card shadow-lg scheduled-2 pt-2">
        <div class="card-body p-3 ">
            <ul class="nav nav-pills" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="scope-tab" data-bs-toggle="tab" data-bs-target="#scope" type="button"
                        @click="currentTab = 'scope'"
                        role="tab" aria-controls="scope" aria-selected="false">
                        <i class="fas fa-th-list"></i>
                        <span class="ms-1">Scope</span></button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="schedule2-tab" data-bs-toggle="tab" data-bs-target="#schedule2"
                    @click="currentTab = 'schedule2'"
                        type="button" role="tab" aria-controls="schedule2" aria-selected="true">
                        <i class="fas fa-table"></i>
                  <span class="ms-1">Schedule 2</span>
                        
                    </button>
                </li>
            </ul>
            <div class="tab-content p-3" id="myTabContent">
                <div class="tab-pane fade " id="schedule2" role="tabpanel" aria-labelledby="schedule2-tab">
                    <PropertySchedule2 :property="property" v-if="currentTab === 'schedule2'"
                        :contractStatus="contract?.status"
                        :scopeFileUrl="contract?.scope" :loadContract="loadContract" />
                </div>
                <div class="tab-pane fade show active" id="scope" role="tabpanel" aria-labelledby="scope-tab" >
                    <PropertyScope
                    v-if="currentTab === 'scope'"
                    :propertyId="property.id"
                    :contractId="contract?.contract_number_id"
                    :contractStatus="contract?.status"
                    :address="property.address"
                    :loadContract="loadContract" 
                    :propertyMetadata="property.metadata"
                    :scopeFileUrl="contract?.scope"
                    />
                </div>
            </div>
        </div>
    </div>

</template>