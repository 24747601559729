<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import ArgonInput from "@/components/ArgonInput.vue";
import { useToast } from 'vue-toast-notification';
import axios from 'axios';
import { watch } from 'vue';
import { useRoute } from "vue-router";
import { toCurrency } from '../../assets/js/helpers';

// Props
const props = defineProps({
    property: {
        type: Object,
    },
    onDelete: {
        type: Function,
    },
    addNew: {
        type: Boolean,
    },
    selected: {
        type: Boolean,
    },
    addNewFunction: {
        type: Function,
    },
    onUpdateProperty: {
        type: Function,

    },
    onSelectProperty: {
        type: Function,
    },
    propIndex: {
        type: Number,
    },
    contractStatus: {
        type: Number
    },
    loadContract: {
        type: Function,
    },
    propertyCount: {
        type: Number
    }

});

// Data
const property = ref({ ...props.property });
const cloneProperty = ref({ ...props.property });
const isEditable = ref(props.property?.isEditable || false);

watch(() => props.property, (newVal) => {
    property.value = { ...newVal };
    cloneProperty.value = { ...newVal };

});


// Vuex Store
const store = useStore();
const apiEndpoint = computed(() => store.state.apiEndpoint);
const token = localStorage.getItem('token');
const apiUrl = `${apiEndpoint.value}/edit_property/`;
const showSubMenu = ref(false);
const toast = useToast();
const routes = useRoute();


// Methods
const toggleEdit = () => {
    isEditable.value = !isEditable.value;
    if (!isEditable.value) {
        editProperty();
    }
};

const editProperty = async () => {
    try {
        const response = await axios.post(apiUrl, {
            id: property.value.id,
            address: property.value.address,
            order: property.value.order,
            contractSlug: routes.params.id,
            isNew: property.value.isNew,
            action: 'edit',
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.data.property_id) {

            property.value.id = response.data.property_id;
            property.value.isEditable = false;
            props.onUpdateProperty(property.value);
            isEditable.value = false;
            
            await props.loadContract();
            toast.success("Property updated successfully");
        }

    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            toast.error(error.response.data.detail);
        } else {
            toast.error("An error occurred while updating property");
        }
    }
};

const deleteProperty = async () => {
    try {
        const propertyID = property.value.id

        await axios.post(apiUrl, {
            id: propertyID,
            action: 'delete',
            contractSlug: routes.params.id
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        await props.loadContract();
        props.onDelete(propertyID);


    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            toast.error(error.response.data.detail);
        } else {
            toast.error("An error occurred while deleting property");
        }
    }
};

const handleCardClick = () => {
    if (!props.addNew && !isEditable.value) {
        const prop = { ...props.property};
        props.onSelectProperty(prop);
    } else if (props.addNew) {
        props.addNewFunction();
    }
};

const orderOnChange = (e) => {
    const newProp = { ...property.value }
    const newOrder = e.target.value;
    if (isNaN(newOrder)) return;
    if(newOrder > props.propertyCount){
        newProp.order = props.propertyCount
    }
    else if (newOrder < 1){
        newProp.order = 1
    } else {
        newProp.order = newOrder
    }
    property.value = newProp

}

const handleSubmenuToggle = () => {
    if(!isEditable.value) {
        isEditable.value = true
    } else {
        isEditable.value = false;
        property.value = { ...cloneProperty.value }
    }
}



</script>


<template>
    <div class="card" style="cursor: pointer;" :class="selected ? 'bg-gradient-info text-white' : ''">
        <div v-if="!addNew" class="card-action" style="position: absolute; top: 0; right: 0;">
            <a href="#" class="p-0 nav-link p-3" :class="[showSubMenu ? 'show' : '']" id="dropdownMenuButton"
                v-if="contractStatus === 1" data-bs-toggle="dropdown" aria-expanded="false"
                @click="showSubMenu = !showSubMenu">
                <i class="fa fa-ellipsis-v" aria-hidden="true" :class="selected ? 'text-white' : ''"></i>

            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showSubMenu ? 'show' : ''"
                aria-labelledby="dropdownMenuButton">
                <li class="mb-2">
                    <a class="dropdown-item border-radius-md" @click="handleSubmenuToggle">
                        <div class="py-1 d-flex align-items-center gap-3 text-start">
                            <div class="my-auto">
                                <i class="fas"
                                :class="isEditable ? 'fa-times' : 'fa-edit'"
                                ></i>
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                                {{ isEditable ? 'Cancel' : 'Edit Property' }}
                            </div>
                        </div>
                    </a>
                </li>
                <li class="mb-2">
                    <a class="dropdown-item border-radius-md" @click="deleteProperty">
                        <div class="py-1 d-flex align-items-center gap-3 text-start">
                            <div class="my-auto">
                                <i class="fas fa-trash text-danger "></i>
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                                Delete Property
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-body" @click="() => handleCardClick()">
            <div v-if="addNew" class="d-flex justify-content-center align-items-center" style="height: 100%;">
                <i class="fas fa-plus text-primary text-4xl"></i>
            </div>
            <template v-else>
                <div class="card-title d-flex align-items-center flex-column">
                    <div class="rounded-3 p-2 mt-n5 bg-gradient-info text-white">
                        <i class="fas fa-home text-3xl"></i>
                    </div>
                    <div class="mt-3 fw-bold w-100">
                        <div class="d-flex flex-column">
                            <div>
                                <label v-if="isEditable">Address:</label>
                                <argon-input v-if="isEditable" type="text" v-model="property.address"
                                    placeholder="Property Address" class="flex-grow-1" />

                                <span v-if="!isEditable">{{ property.address }}</span>

                            </div>
                            <div >
                                <label v-if="isEditable">Order:</label>
                                <argon-input v-if="isEditable" type="number" @change="orderOnChange" :value="property.order"
                                    placeholder="Property Order"  data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Property Order" />
                            </div>
                        </div>

                    </div>
                </div>
                <hr class="horizontal dark" />
                <div class="d-flex justify-content-between mt-4">
                    <div>
                        <p class="lh-05 fwt-bold fs-5">{{toCurrency(property.totals?.subTotal.incTotal || 0.00) }}</p>
                        <p class="lh-05">Sub Total
                            {{ property.schedule2?.subTotal }}
                        </p>
                    </div>
                    <div>
                        <p class="lh-05 fwt-bold fs-5">{{toCurrency(property.totals?.provisionalSums || 0.00) }}</p>
                        <p class="lh-05">Provisional Sums</p>
                    </div>
                    <div>
                        <p class="lh-05 fwt-bold fs-5">{{ toCurrency(property.totals?.tenderTotal || 0.00) }}</p>
                        <p class="lh-05">Tender Total</p>
                    </div>


                </div>

            </template>
        </div>
        <div v-if="isEditable" class="card-footer d-flex justify-content-center">
            <button class="btn btn-primary" @click="toggleEdit()">
                <i class="fas fa-save me-2"></i> Save</button>
        </div>

    </div>
</template>
