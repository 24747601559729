<template>
 <LoadingSpinner 
      v-if="loading" 
      :full-screen="true" 
      message="Downloading..." 
    />
  <div class="position-fixed bottom-3 end-0 translate-middle-x">

    <div class="text-white bg-gradient-primary rounded-pill" v-if="!closeMenu">
      <div class="menu-container d-flex align-items-center justify-content-center"
        :class="{ 'menu-expanded': showMenu }" @mouseenter="showMenu = true" @mouseleave="showMenu = false">
        <div class="d-flex align-items-center overflow-hidden flex-column" :class="{ 'ms-5': !showMenu }">
          <a class="btn btn-link m-0 my-2 " @click="downloadSchedule3" data-bs-toggle="tooltip"
            title="Download Tender Schedule 3" data-bs-placement="left">
            <i class="fas fa-dollar-sign text-white fs-5"></i>
          </a>
          <a class="btn btn-link m-0 my-2" @click="downloadCombined"  data-bs-toggle="tooltip" title="Download Combined Tender Sums"
            data-bs-placement="left">
            <i class="fas fa-folder-open text-white fs-5"></i>
          </a>
          <a class="btn btn-link m-0 my-2" target="_blank" download :href="scopeFileUrl" v-if="scopeFileUrl"
            data-bs-toggle="tooltip" title="Download scope/contract file" data-bs-placement="left">
            <i class="fas fa-eye text-white fs-5"></i>
          </a>
        </div>
        <div class="ellipsis-container" v-if="!showMenu">
          <i class="fas fa-download text-white fs-5"></i>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { useStore } from 'vuex'
import {  ref } from 'vue'

import { useRouter } from 'vue-router'
import axios from 'axios'
import setTooltip from "@/assets/js/tooltip.js";
import { useToast } from 'vue-toast-notification';
import LoadingSpinner from './LoadingSpinner.vue';
export default {
  props: {
    scopeFileUrl: {
      type: String,
      default: ""
    },
  },
  components: {
    LoadingSpinner
  },
  data() {
    return {
      showMenu: false,
      closeMenu: false,
    }
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    const toast = useToast();

    const downloadSchedule3 = async () => {
      loading.value = true
      const token = localStorage.getItem('token')
      const contractID = router.currentRoute.value.params.id
      try {
        // Set responseType to 'arraybuffer' to handle binary data (PDF)
        const response = await axios.get(`${store.state.apiEndpoint}/download_schedule3/${contractID}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        })

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' })

        // Create an Object URL for the Blob
        const url = window.URL.createObjectURL(blob)

        // Create a link element and trigger the download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `schedule3_${contractID}.pdf`)
        document.body.appendChild(link)
        link.click()

        // Clean up the Object URL
        window.URL.revokeObjectURL(url)
        loading.value = false
      } catch (error) {
        toast.error("An error occurred while downloading");
        loading.value = false
        console.error(error)
      }
    }


    const downloadCombined = async () => {
      loading.value = true
      const token = localStorage.getItem('token')
      const contractID = router.currentRoute.value.params.id
      try {
        // Set responseType to 'arraybuffer' to handle binary data (PDF)
        const response = await axios.get(`${store.state.apiEndpoint}/download_combined/${contractID}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        })

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' })

        // Create an Object URL for the Blob
        const url = window.URL.createObjectURL(blob)

        // Create a link element and trigger the download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `combined_${contractID}.zip`)
        document.body.appendChild(link)
        link.click()

        // Clean up the Object URL
        window.URL.revokeObjectURL(url)
        loading.value = false

      } catch (error) {
        loading.value = false
        toast.error("An error occurred while downloading");
        console.error(error)
      }
    }
    return {
      downloadSchedule3,
      downloadCombined,
      loading

    }
  },
  mounted() {
    setTooltip()
  },
  methods: {
    handleClosingMenu() {
      this.closeMenu = true
    }
  }
}
</script>
