<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const user = localStorage.getItem("user");
const route = useRoute();

const toggleNavbar = () => store.commit("toggleNavbar");


</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg shadow-none border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 "
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center ms-md-auto"
        >
      
        </div>
        <ul class="navbar-nav justify-content-end align-items-center gap-4">
          <li>
            <router-link :to="{ name: 'New Contract' }"  class="btn btn-primary text-light btn-sm mb-0 p-2 fwt-bold " >
              <i class="fa fa-plus me-2"></i>
              New Contract
            </router-link>
          </li>
          <li style="border-right: 1px solid lightgray; height:30px;"></li>
          <li class="text-light" >{{ user }}</li>
          
          <li class="nav-item  d-flex align-items-center">
            <a
              href="#"
              @click="toggleNavbar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        
        
        </ul>
      </div>
    </div>
  </nav>
</template>
