<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  // Remove tokens from local storage
  localStorage.clear();

  // Redirect to sign-in page
  router.push("/signin");
});
</script>

<template>
  <div>Signing out...</div>
</template>
