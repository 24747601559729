<!-- LoadingSpinner.vue -->
<template>
    <div class="loading-spinner-container" :class="{ 'full-screen': fullScreen }">
      <div class="loading-spinner">
        <div class="spinner-border text-primary"></div>
        <p v-if="message" class="loading-message">{{ message }}</p>
      </div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingSpinner',
    props: {
      fullScreen: {
        type: Boolean,
        default: false
      },
      message: {
        type: String,
        default: 'Loading...'
      }
    }
  }
  </script>
  
  <style scoped>
  .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .loading-spinner-container.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
  }
  
  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  
  .loading-message {
    margin-top: 10px;
    color: #333;
    font-size: 0.9rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>