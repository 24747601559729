<script setup>
import { reactive, computed, ref, watch, onMounted } from 'vue';
import axios from 'axios';
import { calculateBuildersMargin, scheduled2sections, toCurrency } from '../../assets/js/helpers';
import { useStore } from 'vuex';
import PDFVuer from './PDFVuer.vue';
import { useToast } from 'vue-toast-notification';
import setTooltip from "@/assets/js/tooltip.js";
import UnsavedChangesAlert from './UnsavedChangesAlert.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';


// Props
const props = defineProps({
    property: {
        type: Object,
    },
    scopeFileUrl: {
        type: String
    },
    loadContract: {
        type: Function
    },
    contractStatus: {
        type: Number
    }
});

const store = useStore();
const toast = useToast();
const unsavedChanges = ref(false);
onMounted(async() => {
    setTooltip();
    await getSchedule2();
});
const apiEndpoint = store.state.apiEndpoint;

const loading = ref(false);
const gst = store.state.gst;
const token = localStorage.getItem("token");
const showPdf = ref(false);
const currentSection = ref('');
const buildersMargain = ref(props.property?.builders_margin || 0);
const tenderTotal = computed(() => (parseFloat(subTotal.value.incTotal) + parseFloat(schedule2Obj.value?.provisionalSums||0.00)).toFixed(2));
const disabledEdit = computed(() => props.contractStatus !== 1);

// TODO: make it an API call instead
const schedule2Obj = ref({});
const headers = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
}

const getSchedule2 = async () => {
    loading.value = true;
    try {
        const response = await axios.get(`${apiEndpoint}/property/get-schedule2/${props.property?.id}`, headers);
        if (response.data.schedule2 && Object.keys(response.data.schedule2).length !== 0) {
            // Assign scope
            schedule2Obj.value = response.data.schedule2;
            //sections.value = response.data.schedule2.sections
        } else {
            // If no scope found then set to default
            schedule2Obj.value = scheduled2sections
        }
    } catch (error) {
        console.error(error);
        toast.error("Failed to retrieve schedule 2 data");
    } finally {
        loading.value = false;
    }
};

watch(
    () => props.property,
    async (newVal) => {
        if (newVal) {
            await getSchedule2();
        }
    }
);


// Calculate the subtotals
const subTotal = computed(() => {
    let excTotal = 0;
    let gstTotal = 0;
    let incTotal = 0;
    for (const category in schedule2Obj.value.sections) {
        schedule2Obj.value.sections[category].items.forEach(section => {
            excTotal += parseFloat(section.price);
            gstTotal += parseFloat(section.gst);
            incTotal += parseFloat(section.gstInc);
        });
    }
    return {
        excTotal: excTotal.toFixed(2),
        gstTotal: gstTotal.toFixed(2),
        incTotal: incTotal.toFixed(2),
    };
});


const handleProvisionalChange = (e) => {
    const currentValue = parseFloat(schedule2Obj.value.provisionalSums)
    const newValue = parseFloat(e.target.value).toFixed(2)
    if (e.target.value) {
        schedule2Obj.value.provisionalSums = newValue
    } else {
        schedule2Obj.value.provisionalSums = (0.00).toFixed(2)
    }

    if (newValue !== currentValue) {
        unsavedChanges.value = true
    }
}



const updatePricing = (e, category, sectionIndex, itemIndex = null, isExc = false) => {
    // Determine if we're updating a section or an item
    const isSection = itemIndex === null;
    const parentArray = schedule2Obj.value.sections[category].items;
    const currentItem = isSection
        ? parentArray[sectionIndex]
        : parentArray[sectionIndex].items[itemIndex];

    const price = parseFloat(e.target.value);
    let changed = false;
    // isExc is if input is exclusive of GST
    if (isExc && price !== Number(currentItem.price)) {
        // Save the initial cost
        currentItem.cost = parseFloat(price).toFixed(2);

        // apply builders margin
        currentItem.price = calculateBuildersMargin(price, buildersMargain.value).toFixed(2);

        // add GST with margin
        currentItem.gst = (parseFloat(currentItem.price) * gst).toFixed(2);

        changed = true;
    } else if (!isExc && price !== Number(currentItem.gst)) {
        // Else the user is inputing GST value
        currentItem.gst = price.toFixed(2);
        changed = true;

    }

    if (changed) {
        currentItem.gstInc = (parseFloat(currentItem.price) + parseFloat(currentItem.gst)).toFixed(2);

        // Calculate GST inclusive price
        // If it's an item, update the parent section
        if (!isSection) {
            updateParent(
                category,
                sectionIndex,
            );
        }
        unsavedChanges.value = true;
    }
};


const updateParent = (category, sectionIndex) => {
    const section = schedule2Obj.value.sections[category].items[sectionIndex];
    // Update section price and GST based on items
    const itemTotals = section.items.reduce(
        (totals, itm) => {
            const itemPrice = parseFloat(itm.price);
            totals.price += parseFloat(itemPrice);
            totals.gst += parseFloat(itm.gst);
            return totals;
        },
        { price: 0, gst: 0 }
    );

    section.price = itemTotals.price.toFixed(2);
    section.gst = itemTotals.gst.toFixed(2);
    section.gstInc = (itemTotals.price + itemTotals.gst).toFixed(2);
}

const handleSave = async () => {
    loading.value = true;
    try {
        await axios.put(`${apiEndpoint}/property/save-schedule2/${props.property.id}/`, {
            schedule2: {
                sections: schedule2Obj.value.sections,
                subTotal: subTotal.value,
                tenderTotal: tenderTotal.value,
                provisionalSums: schedule2Obj.value.provisionalSums,
                locked: schedule2Obj.value.locked
            },
            builders_margin: buildersMargain.value
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        toast.success("Schedule 2 saved successfully");
        unsavedChanges.value = false;
        await props.loadContract();
        loading.value = false
    } catch (error) {
        console.error(error);
        toast.error("An error occurred while saving Schedule 2");
        loading.value = false
    }
};

const handleDownloadSchedule2 = async () => {
    loading.value = true;
    try {
        // Set responseType to 'arraybuffer' to handle binary data (PDF)
        const response = await axios.get(`${store.state.apiEndpoint}/property/download_schedule2/${props.property.id}/`, {...headers,
            responseType: 'arraybuffer',
        })

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' })

        // Create an Object URL for the Blob
        const url = window.URL.createObjectURL(blob)

        // Create a link element and trigger the download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `schedule2_${props.property.address}.pdf`)
        document.body.appendChild(link)
        link.click()

        // Clean up the Object URL
        window.URL.revokeObjectURL(url)
        loading.value = false
    } catch (error) {
        console.error(error)
        toast.error("An error occurred while downloading Schedule 2")
        loading.value = false
    }
}

const setSection = (sectId) => {
    const propertyMetadata = props.property?.metadata;
    const section = propertyMetadata.sections[sectId];
    currentSection.value = section;
    showPdf.value = true;

};
const resetTable = () => {
    schedule2Obj.value=  scheduled2sections;
    unsavedChanges.value = true;
}

const onMarginBlur = (e) => {
    if (e.target.value === buildersMargain.value) return
    buildersMargain.value = Number(e.target.value);
    // Update section totals
    for (const category in schedule2Obj.value.sections) {
        const sectionItems = schedule2Obj.value.sections[category].items;

        for (let i = 0; i < sectionItems.length; i++) {
            const subItems = sectionItems[i].items;

            if (subItems.length > 0) {
                const updatedSubItems = [...subItems];
                for (let j = 0; j < updatedSubItems.length; j++) {
                    const cost = parseFloat(updatedSubItems[j].cost);
                    if (!cost) continue;
                    updatedSubItems[j].price = calculateBuildersMargin(cost, buildersMargain.value).toFixed(2);
                    updatedSubItems[j].gst = (parseFloat(updatedSubItems[j].price) * gst).toFixed(2);
                    updatedSubItems[j].gstInc = (parseFloat(updatedSubItems[j].price) + parseFloat(updatedSubItems[j].gst)).toFixed(2);
                }
                sectionItems[i].items = updatedSubItems;
                updateParent(category, i);

            } else {
                const section = sectionItems[i];
                const cost = parseFloat(section.cost);
                if (!cost) continue;
                section.price = calculateBuildersMargin(cost, buildersMargain.value).toFixed(2);
                section.gst = (parseFloat(section.price) * gst).toFixed(2);
                section.gstInc = (parseFloat(section.price) + parseFloat(section.gst)).toFixed(2);
            }

        }

        // update 
        schedule2Obj.value.sections[category].items = sectionItems;
    }
    unsavedChanges.value = true;
}

const handleLockOnChanged = (e) => {
    schedule2Obj.value.locked = e.target.checked;
    unsavedChanges.value = true
}

</script>

<template>
    <LoadingSpinner v-if="loading" message="Loading..." :fullScreen="true" />
    <div>
        <div class="schedule-header d-flex justify-content-between">
            <div>
                <h4 class="fw-bold">
                    Tender Schedule 2.{{ property?.order }} - Tender Cost Breakdown
                </h4>
                <p class="lh-small property-address">
                    {{ property?.address }}
                </p>
                <small>Provide trade/works breakdowns as per this schedule.</small>
            </div>
            <div class="d-flex gap-4 align-items-center mb-4">
                <div class="d-flex flex-column">
                    <div class="d-flex gap-3">
                        <div class="mt-2" data-bs-toggle="tooltip"
                            title="When the table is locked, changes in Scope will not affect totals here"
                            data-bs-placement="top">
                            <div class="form-check form-switch ps-0">
                                <input class="form-check-input ms-0" type="checkbox" :checked="schedule2Obj.locked"
                                :disabled="disabledEdit"
                                    @change="handleLockOnChanged" />
                                <label class="form-check-label">
                                    Lock Table
                                </label>
                            </div>
                        </div>
                        <button data-bs-toggle="tooltip" title="Download PDF of this property's Schedule 2"
                            data-bs-placement="top" class="btn btn-link text-info" @click="handleDownloadSchedule2"><i
                                class="fas fa-file-pdf me-2"></i>PDF</button>

                        <button data-bs-toggle="tooltip" title="Clicking this will reset all prices to 0"
                        v-if="!disabledEdit"
                            data-bs-placement="top" class="btn btn-link text-info" @click="resetTable"><i
                                class="fas fa-sync me-2"></i>Reset</button>

                        <button class="btn bg-gradient-info" @click="handleSave"  v-if="!disabledEdit" ><i
                                class="fas fa-save me-2"></i>Save</button>
                    </div>

                    
                    <div class="input-group w-50 ms-auto" data-bs-toggle="tooltip"
                        title="Property Specific Builders Margin" data-bs-placement="top">
                        <span class="input-group-text bg-light">%</span>
                        <input class="form-control ps-2" type="number" v-model.number="buildersMargain"
                        :disabled="disabledEdit"
                            @blur="(e) => onMarginBlur(e)" step="0.01" />
                    </div>
                </div>
            </div>

        </div>
        <div class="schedule-table w-100">
            <table class="tender-table">
                <thead>
                    <tr>
                        <th>Scope</th>
                        <th></th>
                        <th class="right-border">Price (ex. GST)</th>
                        <th>GST</th>
                        <th>Tender GST Incl.</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Loop through each category (Internal, External, Other) -->
                    <template v-for="(category, catIndex) in schedule2Obj.sections" :key="category.order">
                        <!-- Category Header -->
                        <tr v-if="category.title">
                            <td colspan="5"><strong>{{ category.title }}</strong></td>
                        </tr>

                        <!-- Loop through each section within the category -->
                        <template v-for="(item, sectionIndex) in category.items" :key="item.id">
                            <!-- Section Row -->
                            <tr>
                                <td class="text-center" width="10%"><strong>{{ item.code }}</strong></td>
                                <td class="right-border">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <strong>{{ item.title }}</strong>
                                        </div>
                                        <div>
                                            <button v-if="property?.metadata?.sections?.[item.id]?.page"
                                                class="btn btn-sm btn-link" @click="setSection(item.id)">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="right-border px-3">
                                    <div class="input-group">
                                        <span class="input-group-text bg-light">$</span>
                                        <input class="form-control ps-2" type="number" :value="item.price"
                                        :disabled="disabledEdit"
                                            @blur="(e) => updatePricing(e, catIndex, sectionIndex, null, true)"
                                            step="0.01" />
                                    </div>
                                </td>
                                <td class="right-border px-3">
                                    <div class="input-group">
                                        <span class="input-group-text bg-light">$</span>
                                        <input class="form-control ps-2" type="number" :value="item.gst"
                                        :disabled="disabledEdit"
                                            @blur="(e) => updatePricing(e, catIndex, sectionIndex)" step="0.01" />
                                    </div>
                                </td>
                                <td>
                                    {{ toCurrency(item.gstInc) }}
                                </td>
                            </tr>

                            <!-- Item Rows within the Section -->
                            <tr v-for="(subItem, itemIndex) in item.items" :key="item.code || `item-${itemIndex}`">
                                <td width="10%" class="text-end"> {{ subItem.code }}</td>
                                <td class="right-border">{{ subItem.title }}</td>
                                <td class="right-border px-3">
                                    <div class="input-group">
                                        <span class="input-group-text bg-light">$</span>
                                        <input class="form-control ps-2" type="number" :value="subItem.price"
                                        :disabled="disabledEdit"
                                            @blur="(e) => updatePricing(e, catIndex, sectionIndex, itemIndex, true)"
                                            step="0.01" />
                                    </div>
                                </td>
                                <td class="right-border px-3">
                                    <div class="input-group">
                                        <span class="input-group-text bg-light">$</span>
                                        <input class="form-control ps-2" type="number" :value="subItem.gst"
                                        :disabled="disabledEdit"
                                            @blur="(e) => updatePricing(e, catIndex, sectionIndex, itemIndex)"
                                            step="0.01" />
                                    </div>
                                </td>
                                <td>
                                    {{ toCurrency(subItem.gstInc) }}
                                </td>
                            </tr>
                        </template>
                    </template>

                    <!-- Sub Total -->
                    <tr>
                        <td class="no-border"></td>
                        <td><strong>SUB TOTAL</strong></td>
                        <td>{{ toCurrency(subTotal.excTotal) }}</td>
                        <td>{{ toCurrency(subTotal.gstTotal) }}</td>
                        <td>{{ toCurrency(subTotal.incTotal) }}</td>
                    </tr>
                    <tr>
                        <td class="no-border"></td>

                        <td class="no-border"><strong>Provisional Sums</strong></td>
                        <td class="no-border"></td>
                        <td></td>
                        <td>
                            <div class="input-group">
                                <span class="input-group-text bg-light">$</span>
                                <input class="form-control ps-2" type="number" :value="schedule2Obj.provisionalSums"
                                :disabled="disabledEdit"
                                    @blur="handleProvisionalChange" step="0.01" />
                            </div>
                        </td>
                    </tr>

                    <!-- Tender Total Amount -->
                    <tr>
                        <td colspan="3" class="no-border"></td>

                        <td><strong>TENDER AMOUNT</strong></td>
                        <td>{{ toCurrency(tenderTotal) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="scopeFileUrl && showPdf" class="pdf-viewer pdf-fixed-bottom">
        <PDFVuer :pdfUrl="scopeFileUrl" :pageNumber="currentSection?.page" :hidePdf="() => showPdf = false"
            :highlightText="currentSection?.schedule2_title" />
    </div>

    <UnsavedChangesAlert v-if="unsavedChanges">
        You have unsaved changes, please <a class="btn btn-link text-info p-0 m-0 px-2 mx-2 bg-light"
            @click="handleSave">SAVE NOW</a>
        to avoid losing them.
    </UnsavedChangesAlert>
</template>

<style></style>
