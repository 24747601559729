<template>
  <div 
    v-if="$slots.default && $slots.default().length"
    class="position-fixed bottom-0 start-0 w-100 d-flex justify-content-center p-3 alert-animation" 
    style="z-index: 1050;"
  >
    <argon-alert color="secondary" class="w-50 text-center">
      <i class="fas fa-exclamation-circle me-2"></i>
      <slot></slot>
    </argon-alert>
  </div>
</template>

<script>
import ArgonAlert from "@/components/ArgonAlert.vue";
export default {
  name: "FixedBottomAlert",
  components: {
    ArgonAlert
  }
}
</script>

<style scoped>
@keyframes fadeUpAnimation {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.alert-animation {
  animation: fadeUpAnimation 0.2s ease-out;
  will-change: transform, opacity;
}
</style>