<template>
   <LoadingSpinner 
      v-if="loading" 
      message="Downloading..." 
    />
  <div class="card p-3 schedule3">
    <div class="container-fluid">
      <div class="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5>Tender Schedule 3 – Schedule of Unit Costs with Energy Efficiency in Social Housing Program (EESHP)</h5>
        </div>
        <div class="d-flex gap-4 align-items-center">
          <div class="mt-n2" data-bs-toggle="tooltip"
            title="When the table is locked, changes in Properties will not affect totals here" data-bs-placement="top">
            <div class="form-check form-switch ps-0">
              <input class="form-check-input ms-0" type="checkbox" :checked="modifiableSchedule3.locked" :disabled="disabledEdit"
                @change="handleLockOnChanged" />
              <label class="form-check-label">
                Lock Table
              </label>
            </div>
          </div>
          <div>
            <button data-bs-toggle="tooltip" title="Download PDF of this property's Schedule 2" data-bs-placement="top"
              class="btn btn-link text-info" @click="downloadSchedule3"><i
                class="fas fa-file-pdf me-2"></i>PDF</button>
            <button class="btn bg-gradient-info btn-sm" @click="handleSave" v-if="!disabledEdit" > <i class="fas fa-save me-1"></i>
              Save</button>
          </div>
        </div>
      </div>

      <!-- Description Text -->
      <p class="small mb-2">This form shall be included when there are a number of separate sites.The total of the
        summarised costs of unit types shall equal the Tender Sum.</p>
      <p class="small mb-4">The tenderer must complete Columns A, B & C and submit these costs at the time of tendering.
        Columns 1 – 10 will be required to be completed upon request by the superintendent.</p>

      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Site/Dwelling Address</th>
              <th v-for="sect in schedule3sections" :key="sect.id">{{ sect.title }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(property, rowIndex) in modifiableSchedule3?.properties" :key="property.id">
              <td>{{ property.address }}</td>
              <td v-for="schedule3section in schedule3sections" :key="schedule3section.id">
                <div class="input-group">
                  <span class="input-group-text bg-light">$</span>
                  <input type="number" class="form-control form-control-sm text-end" step="0.01" min="0"
                  :disabled="disabledEdit"
                    v-model="property.sections[schedule3section.id]" @input="calculateSubtotals(false, schedule3section.id, rowIndex)">
                </div>
              </td>
            </tr>
            <tr class="fw-bold">
              <td>Sub-total</td>
              <td v-for="schedule3section in schedule3sections" :key="schedule3section.id">
               
                <span>{{ toCurrency(modifiableSchedule3.totals[schedule3section.id] )}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <ArgonAlert color="primary" icon="fa fa-home" class="text-center"
          v-if="(modifiableSchedule3.properties?.length === 0 || !modifiableSchedule3.properties) && !disabledEdit">
          To begin, start by quoting a property
        </ArgonAlert>

      </div>
    </div>
  </div>
  <UnsavedChangesAlert v-if="unsavedChanges">
    You have unsaved changes, please <a class="btn btn-link text-info p-0 m-0 px-2 mx-2 bg-light"
      @click="handleSave">SAVE NOW</a>
    to avoid losing them.
  </UnsavedChangesAlert>

</template>

<script>
import { schedule3sections, toCurrency } from '../../assets/js/helpers.js'
import setTooltip from "@/assets/js/tooltip.js";
import { useToast } from 'vue-toast-notification';
import { useRoute } from "vue-router";
import axios from 'axios';
import ArgonAlert from '../../components/ArgonAlert.vue';
import UnsavedChangesAlert from './UnsavedChangesAlert.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';

export default {
  props: {
    contractSchedule3: {
      type: Object,
    },
    loadContract: {
      type: Function,
    },
    contractStatus: {
      type: Number
    }
  },
  data() {
    return {
      schedule3sections,
      toCurrency,
      modifiableSchedule3: {
        ...this.contractSchedule3,
        totals: this.initializeTotals()
      },
      unsavedChanges: false,
      loading: false,
      disabledEdit : this.contractStatus!==1
    }
  },
  components: {
    ArgonAlert,
    UnsavedChangesAlert,
    LoadingSpinner
  },

  mounted() {
    setTooltip();
    this.calculateSubtotals(true);
  },

  methods: {
    // Initialize totals with zeros for each section
    initializeTotals() {
      const totals = {};
      this.schedule3sections?.forEach(section => {
        totals[section.id] = 0;
      });
      return totals;
    },

    // Calculate subtotals for each section
    calculateSubtotals(init, sectionId, propertyIdx) {

      // If the value updated is one of the two key total items, update the tender sum
      if (['total-non-eeshp-including-gst', 'total-eeshp-including-gst'].includes(sectionId)) {
        // Calculated new total tender sum for property
        const propertySections = this.modifiableSchedule3.properties[propertyIdx].sections
        if (propertySections) {
          propertySections['total-tender-sum-including-gst'] = parseFloat((propertySections['total-non-eeshp-including-gst'] + propertySections['total-eeshp-including-gst']).toFixed(2));
          this.modifiableSchedule3.properties[propertyIdx].sections = propertySections
        }
      }
      
      if (this.modifiableSchedule3.properties) {

        // Reset totals to zero before recalculating
        this.schedule3sections.forEach(section => {
          this.modifiableSchedule3.totals[section.id] = 0;
        });

        // Sum up values for each section
        this.modifiableSchedule3.properties.forEach(property => {
          this.schedule3sections.forEach(section => {
            // Convert to number and handle potential NaN
            const sectionValue = Number(property.sections[section.id]) || 0;
            this.modifiableSchedule3.totals[section.id] += sectionValue;
          });
        });

        // Round to 2 decimal places
        this.schedule3sections.forEach(section => {
          this.modifiableSchedule3.totals[section.id] =
            Number(this.modifiableSchedule3.totals[section.id].toFixed(2));
        });
      }

      if (!init) {
        this.unsavedChanges = true
      }
    },

    handleLockOnChanged(e) {
      this.modifiableSchedule3 = {
        ...this.contractSchedule3,
        locked: e.target.checked
      }
      this.unsavedChanges = true
    },

    async handleSave() {
      const apiEndpoint = this.$store.state.apiEndpoint;
      const id = this.$route.params.id;
      const token = localStorage.getItem('token');
      this.loading = true
      try {
        await axios.put(`${apiEndpoint}/save_schedule3/${id}/`, {
          schedule3: this.modifiableSchedule3
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$toast.success("Schedule 3 saved successfully");
        this.unsavedChanges = false
        await this.loadContract();
      } catch (error) {
        this.$toast.error("An error occurred while saving Schedule 3", error);
      }
      this.loading = false

    },
    async downloadSchedule3() {
      const token = localStorage.getItem('token')
      const contractID = this.$route.params.id
      const apiEndpoint = this.$store.state.apiEndpoint;
      this.loading = true

      try {
        // Set responseType to 'arraybuffer' to handle binary data (PDF)
        const response = await axios.get(`${apiEndpoint}/download_schedule3/${contractID}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        })

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' })

        // Create an Object URL for the Blob
        const url = window.URL.createObjectURL(blob)

        // Create a link element and trigger the download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `schedule3_${contractID}.pdf`)
        document.body.appendChild(link)
        link.click()

        // Clean up the Object URL
        window.URL.revokeObjectURL(url)
        this.loading = false
      } catch (error) {
        this.$toast.error("An error occurred while downloading");
        loading.value = false
        console.error(error)
      }
      this.loading = false

    }
  }
}
</script>

<style scoped></style>