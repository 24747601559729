<script setup>
import { ref, onBeforeUnmount, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import logo from "@/assets/img/logo.png";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();
const username = ref("");
const password = ref("");
const error = ref("");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});


const handleSubmit = async () => {
  try {
    // Dispatch the login action and pass the username and password
    await store.dispatch('login', {
      username: username.value,
      password: password.value,
    });
    // If login is successful, redirect to the home page
    router.push("/");
  } catch (err) {
    // Handle error if login fails
    console.log(err.response?.data?.detail || "An error occurred");
    error.value = err.response?.data?.detail || "An error occurred";
  }
};
</script>
<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card py-4">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your username and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="handleSubmit">
                    <div class="mb-3">
                      <argon-input
                        id="username"
                        type="username"
                        placeholder="username"
                        name="username"
                        size="lg"
                        v-model="username"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        v-model="password"
                      />
                    </div>
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        type="submit"
                        >Sign in</argon-button
                      >
                    </div>
                  </form>
                  
                </div>
              </div>
              <div v-if="error" class="alert alert-danger alert-dismissible fade show text-light text-center mt-3 text-uppercase fw-bold px-0" role="alert">
                    {{ error }}
                  </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative  m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
               
              >
              <img
          :src=logo
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

