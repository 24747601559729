<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { useRoute } from "vue-router";
import PropertyCard from "./components/PropertyCard.vue";
import PropertySchedule2 from "./components/PropertySchedule2.vue";
import Schedule3 from "./components/Schedule3.vue";
import ContractSettings from "./components/ContractSettings.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import Property from "./components/Property.vue";
import ContractMenu from "../components/ContractMenu.vue";
import { getStatusColor } from "../assets/js/helpers";

const store = useStore();
const route = useRoute();
const error = ref("");
const properties = ref([]);
const selectedProp = ref(null);
const apiEndpoint = store.state.apiEndpoint;
const token = localStorage.getItem("token");
const currentTab = ref("properties");
const contractObj = ref({})
const loading = ref(true)

const contractId = route.params.id;
const scopeFileUrl = ref("")

onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();

});

onBeforeMount(async () => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = true;
  store.state.showFooter = false;
  store.state.hideConfigButton = true;
  await loadContract();

});

const loadContract = async () => {

  if (token && contractId) {
    try {
      const response = await fetch(`${apiEndpoint}/contracts/${contractId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      contractObj.value = data
      store.state.routeName = data.title;
      properties.value = data.properties;
      scopeFileUrl.value = `${data.scope}`

      loading.value = false
    } catch (err) {
      error.value = "An error occurred while fetching contract data.";
    }
  } else {
    error.value = "Invalid token or contract ID.";
  }
};

onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = false;
  store.state.hideConfigButton = false;
});

const onPropertyDelete = (id) => {
  properties.value = properties.value.filter((property) => property.id !== id);
}


const addNewProperty = () => {
  properties.value.push({
    address: "New Property",
    contract: contractId,
    isEditable: true,
    isNew: true,
    order: properties.value.length + 1
  });
}

const updateProperty = (index, updatedProperty) => {
  properties.value[index] = updatedProperty
}

const selectProperty = (propToBeSelected) => {
  if (selectedProp.value?.id !== propToBeSelected?.id) {
    selectedProp.value = propToBeSelected;
    setTimeout(() => {
      const element = document.getElementById('property-schedule2');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  } else {
    selectedProp.value = null;
  }
}


</script>

<template>
  <LoadingSpinner v-if="loading" :full-screen="true" message="Fetching data..." />
  <div>
    <div class="page-header min-height-300" style="
          margin-left: -34%;
          background-position: center 50%;
          background-size: cover;
        " :style="{
          backgroundImage: 'url(' + require('@/assets/img/houses.jpg') + ')',
        }">
      <span class="mask bg-gradient-secondary opacity-7"></span>
    </div>
    <div class="card shadow-lg mt-n6  mx-3">
      <h1 class="fw-bold text-center mask text-white mt-n8">{{ contractObj.title }}</h1>
      <div class="card-body w-auto p-3">
        <div class=" position-relative d-flex justify-content-between mx-3 align-items-center">
          <ul class="nav nav-pills" id="contractTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                :class="currentTab === 'properties' ? 'active' : ''" @click="currentTab = 'properties'"
                aria-selected="true">
                <i class="fas fa-home"></i>
                <span class="ms-1">Properties</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                :class="currentTab === 'schedule3' ? 'active' : ''" @click="currentTab = 'schedule3'"
                aria-selected="false">
                <i class="fas fa-dollar-sign"></i>
                <span class="ms-1">Tender Schedule 3 (EESHP)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                :class="currentTab === 'contract' ? 'active' : ''" @click="currentTab = 'contract'"
                aria-selected="false">
                <i class="fas fa-cog"></i>
                <span class="ms-1">Contract Settings</span>
              </a>
            </li>
          </ul>
          <div>
            <span class="badge badge-sm" :class="[getStatusColor(contractObj.get_status_display)]">
              {{ contractObj.get_status_display }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid py-4" v-if="currentTab === 'properties'">
    <div class="row mt-3">
      <div v-for="(property, index) in properties" :key="property.id" class="col-md-3 my-4 d-flex align-items-stretch">
        <PropertyCard :property="property" @delete="onPropertyDelete" @update-property="updateProperty(index, $event)"
          @select-property="selectProperty" :selected="selectedProp?.id === property.id" class="w-100"
          :loadContract="loadContract"
          :propertyCount="properties.length"
          :contractStatus="contractObj.status" :propIndex="index" />
      </div>
      <div class="col-md-3 mb-4 d-flex align-items-stretch" v-if="contractObj.status === 1">
        <PropertyCard :addNew="true" :addNewFunction="addNewProperty" class="w-100" />
      </div>
    </div>
    <div v-if="selectedProp" id="property-schedule2">
      <Property :property="selectedProp" :loadContract="loadContract" :contract="contractObj" />
    </div>
  </div>

  <div class="container-fluid py-4" v-if="currentTab === 'schedule3'">
    <Schedule3 :contractSchedule3="contractObj.schedule3" :loadContract="loadContract"
      :contractStatus="contractObj.status" />
  </div>

  <div class="container-fluid py-4" v-if="currentTab === 'contract'">
    <ContractSettings :contractObj="contractObj" :loadContract="loadContract" />
  </div>
  <ContractMenu :scopeFileUrl="scopeFileUrl" />
</template>
