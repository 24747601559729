<template>
  <div class="border border-dark border-1 fwt-bold p-0 d-flex align-items-center p-1"
  :class="`col-${colSize} ${textCenter ? 'text-center' : ''}`" 
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {

    colSize: {
      type: String,
      default: "2"
    },
    textCenter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
