export const calculateBuildersMargin = (value, margin) => {
  return value + value * (margin / 100);
};

export const defaultPageSizes = [
  { value: 5, label: "Show 5" },
  { value: 10, label: "Show 10" },
  { value: 25, label: "Show 25" },
  { value: 50, label: "Show 50" },
  { value: 100, label: "Show 100" },
];
export const  toCurrency =(value) =>{
  // force float
  value = isNaN(value) ? 0 : parseFloat(value);
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AUD'
  });
  return formatter.format(value);
}

export const getStatusColor = (status) => {
  switch (status) {
    case "In Progress":
      return "bg-gradient-warning";
    case "Completed":
      return "bg-gradient-success";
    case "Cancelled":
      return "bg-gradient-danger";
    default:
      return "bg-gradient-info";
  }
};

export const schedule3sections = [
  {
    id: "kitchen-appliances-exclude-tbs",
    title: "Kitchen Appliances (exclude TBS)",
  },
  { id: "hwhp-exclude-tbs-appliance", title: "HWHP (exclude TBS appliance)" },
  { id: "heater-removal-make-good", title: "Heater Removal & make good" },
  {
    id: "split-system-supply-and-install",
    title: "Split System supply and install",
  },
  { id: "mains-power-upgrade", title: "Mains Power Upgrade" },
  { id: "switch-board-upgrade", title: "Switch Board Upgrade" },
  { id: "draught-proofing", title: "Draught Proofing" },
  { id: "insulation", title: "Insulation" },
  { id: "gas-abolishment", title: "Gas Abolishment" },
  { id: "eeshp-others", title: "EESHP Others" },
  { id: "total-eeshp-including-gst", title: "TOTAL EESHP including GST" },
  {
    id: "total-non-eeshp-including-gst",
    title: "TOTAL NON-EESHP (including GST)",
  },
  {
    id: "total-tender-sum-including-gst",
    title: "TOTAL TENDER SUM (including GST)",
  },
];

export const scheduled2sections = {
  locked: false,
  subTotal: 0.0,
  tenderTotal: 0.0,
  provisionalSums: 0.0,
  sections: [
    {
      order: 1,
      title: "",
      items: [
        {
          code: "P",
          title: "Preliminaries",
          id: "preliminaries",
          schedule2_title: "Preliminaries",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
      ],
    },
    {
      order: 2,
      title: "INTERNAL",
      items: [
        {
          code: 101,
          title: "Kitchen Fittings",
          id: "kitchen-fittings",
          schedule2_title: "Kitchen Fittings",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [
            {
              code: "EESHP",
              title: "Kitchen appliances installation",
              id: "kitchen-appliances-installation",
              schedule2_title: "Kitchen appliances installation",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "kitchen-appliances-exclude-tbs",
            },
          ],
        },
        {
          code: 102,
          title: "Bathroom Fittings",
          id: "bathroom-fittings",
          schedule2_title: "Bathroom Fittings",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 103,
          title: "Laundry Fittings",
          id: "laundry-fittings",
          schedule2_title: "Laundry Fittings",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 104,
          title: "Floor Coverings",
          id: "floor-coverings",
          schedule2_title: "Floor Coverings",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 105,
          title: "Wall preparation, Painting",
          id: "wall-preparation-painting",
          schedule2_title: "Wall preparation, Painting",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 106,
          title: "Plumbing Services",
          id: "plumbing-services",
          schedule2_title: "Plumbing Services",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 107,
          title: "Electrical/Mechanical Services",
          id: "electrical-mechanical-services",
          schedule2_title: "Electrical/Mechanical Services",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [
            {
              code: "EESHP",
              title: "Heater removal and make good",
              id: "heater-removal-and-make-good",
              schedule2_title: "Heater removal and make good",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "heater-removal-make-good",
            },
            {
              code: "EESHP",
              title: "Split system supply and installation",
              id: "split-system-supply-and-installation",
              schedule2_title: "Split system supply and installation",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "split-system-supply-and-install",
            },
            {
              code: "",
              title: "Mandatory electrical safety check",
              id: "mandatory-electrical-safety-check",
              schedule2_title: "Mandatory electrical safety check",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "",
            },
            {
              code: "EESHP",
              title: "Mains power upgrade",
              id: "mains-power-upgrade",
              schedule2_title: "Mains power upgrade",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "mains-power-upgrade",
            },
            {
              code: "EESHP",
              title: "Switchboard upgrade EESHP",
              id: "switchboard-upgrade-eeshp",
              schedule2_title: "Switchboard upgrade EESHP",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "switch-board-upgrade",
            },
          ],
        },
        {
          code: 108,
          title: "Windows/Doors/Joinery",
          id: "windows-doors-joinery",
          schedule2_title: "Windows/Doors/Joinery",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [
            {
              code: "EESHP",
              title: "Draught proofing",
              id: "draught-proofing",
              schedule2_title: "Draught proofing",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "draught-proofing",
            },
          ],
        },
        {
          code: 109,
          title: "Curtains and blinds",
          id: "curtains-and-blinds",
          schedule2_title: "Curtains and blinds",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 110,
          title: "Insulation",
          id: "insulation",
          schedule2_title: "Insulation",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [
            {
              code: "EESHP",
              title: "Ceiling insulation incl remove existing",
              id: "ceiling-insulation-incl-remove-existing",
              schedule2_title: "Ceiling insulation incl remove existing",
              price: 0.0,
              cost: 0.0,
              gst: 0.0,
              gstInc: 0.0,
              related_schedule3_id: "insulation",
            },
          ],
        },
        {
          code: 112,
          title: "Structural Works",
          id: "structural-works",
          schedule2_title: "Structural Works",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 113,
          title: "Asbestos/Haz Mat removal",
          id: "asbestos-haz-mat-removal",
          schedule2_title: "Asbestos/Haz Mat removal",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 114,
          title: "Other Internal Work",
          id: "other-internal-work",
          schedule2_title: "Other Internal Work",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
      ],
    },
    {
      order: 3,
      title: "EXTERNAL",
      items: [
        {
          code: 201,
          title: "Roofing",
          id: "roofing",
          schedule2_title: "Roofing",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 202,
          title: "Outside installation and drainage",
          id: "outside-installation-and-drainage",
          schedule2_title: "Outside installation and drainage",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 203,
          title: "Exterior Cladding",
          id: "exterior-cladding",
          schedule2_title: "Exterior Cladding",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 204,
          title: "Security Doors",
          id: "security-doors",
          schedule2_title: "Security Doors",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 205,
          title: "Fencing",
          id: "fencing",
          schedule2_title: "Fencing",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 206,
          title: "Site improvements",
          id: "site-improvements",
          schedule2_title: "Site improvements",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 207,
          title: "Asbestos/Haz Mat removal",
          id: "ext-asbestos-haz-mat-removal",
          schedule2_title: "Asbestos/Haz Mat removal",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 208,
          title: "Painting",
          id: "painting",
          schedule2_title: "Painting",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
        {
          code: 209,
          title: "Other external work",
          id: "other-external-work",
          schedule2_title: "Other external work",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "",
        },
      ],
    },
    {
      order: 4,
      title: "OTHER",
      items: [
        {
          code: "S1",
          title: "Disability Modifications",
          id: "disability-modifications",
          schedule2_title: "Disability Modifications",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          items: [],
          related_schedule3_id: "eeshp-others",
        },
      ],
    },
  ],
};

export const scopeSections = [
  { id: "preliminaries", code: "P", order: 1, title: "Preliminaries" },
  {
    id: "kitchen-fittings",
    code: 101,
    order: 2,
    title: "Kitchen Fittings and Appliances",
    items: [
      {
        code: "EESHP",
        title: "Kitchen appliances installation",
        id: "kitchen-appliances-installation",
        schedule2_title: "Kitchen appliances installation",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "kitchen-appliances-exclude-tbs",
      },
    ],
  },
  { id: "bathroom-fittings", code: 102, order: 3, title: "Bathroom Fittings" },
  { id: "laundry-fittings", code: 103, order: 4, title: "Laundry Fittings" },
  { id: "floor-coverings", code: 104, order: 5, title: "Floor Coverings" },
  {
    id: "wall-preparation-painting",
    code: 105,
    order: 6,
    title: "Wall preparation, Painting",
  },
  { id: "plumbing-services", code: 106, order: 7, title: "Plumbing Services" },
  {
    id: "electrical-mechanical-services",
    code: 107,
    order: 8,
    title: "Electrical / Mechanical Services",
    items: [
      {
        code: "EESHP",
        title: "Heater removal and make good",
        id: "heater-removal-and-make-good",
        schedule2_title: "Heater removal and make good",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "heater-removal-make-good",
      },
      {
        code: "EESHP",
        title: "Split system supply and installation",
        id: "split-system-supply-and-installation",
        schedule2_title: "Split system supply and installation",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "split-system-supply-and-install",
      },
      {
        code: "",
        title: "Mandatory electrical safety check",
        id: "mandatory-electrical-safety-check",
        schedule2_title: "Mandatory electrical safety check",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "",
      },
      {
        code: "EESHP",
        title: "Mains power upgrade",
        id: "mains-power-upgrade",
        schedule2_title: "Mains power upgrade",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "mains-power-upgrade",
      },
      {
        code: "EESHP",
        title: "Switchboard upgrade EESHP",
        id: "switchboard-upgrade-eeshp",
        schedule2_title: "Switchboard upgrade EESHP",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "switch-board-upgrade",
      },
    ],
  },
  {
    id: "windows-doors-joinery",
    code: 108,
    order: 9,
    title: "Windows / Doors / Joinery",
    items: [
      {
        code: "EESHP",
        title: "Draught proofing",
        id: "draught-proofing",
        schedule2_title: "Draught proofing",
        price: 0.0,
        cost: 0.0,
        gst: 0.0,
        gstInc: 0.0,
        related_schedule3_id: "draught-proofing",
      },
    ],
  },
  {
    id: "curtains-and-blinds",
    code: 109,
    order: 10,
    title: "Blinds and Curtains",
  },
  { id: "insulation", code: 110, order: 11, title: "Insulation" },
  { id: "structural-works", code: 112, order: 12, title: "Structural Works" },
  {
    id: "asbestos-haz-mat-removal",
    code: 113,
    order: 13,
    title: "Asbestos/Hazardous Mat. Removal",
  },
  {
    id: "other-internal-work",
    code: 114,
    order: 14,
    title: "Other Internal Work",
  },
  { id: "roofing", code: 201, order: 15, title: "Roofing" },
  {
    id: "outside-installation-and-drainage",
    code: 202,
    order: 16,
    title: "Outside installations and drainage",
  },
  { id: "exterior-cladding", code: 203, order: 17, title: "Exterior Cladding" },
  { id: "security-doors", code: 204, order: 18, title: "Security Doors" },
  { id: "fencing", code: 205, order: 19, title: "Fencing" },
  { id: "site-improvements", code: 206, order: 20, title: "Site improvements" },
  {
    id: "ext-asbestos-haz-mat-removal",
    code: 207,
    order: 21,
    title: "Asbestos/Hazardous Mat. Removal",
  },
  { id: "painting", code: 208, order: 22, title: "Painting" },
  {
    id: "other-external-work",
    code: 209,
    order: 23,
    title: "Other Exterior Work",
  },
  {
    id: "disability-modifications",
    code: "S1",
    order: 24,
    title: "Disability Modifications",
  },
];

export const scopeItemsDefault = {
  fencing: {
    items: [],
    section: {
      id: "fencing",
      code: 205,
      order: 19,
      title: "Fencing",
      schedule2_title: "Fencing",
    },
  },
  roofing: {
    items: [],
    section: {
      id: "roofing",
      code: 201,
      order: 15,
      title: "Roofing",
      schedule2_title: "Roofing",
    },
  },
  painting: {
    items: [],
    section: {
      id: "painting",
      code: 208,
      order: 22,
      title: "Painting",
      schedule2_title: "Painting",
    },
  },
  insulation: {
    items: [],
    section: {
      id: "insulation",
      code: 110,
      order: 11,
      title: "Insulation",
      schedule2_title: "Insulation",
      items: [
        {
          code: "EESHP",
          title: "Ceiling insulation incl remove existing",
          id: "ceiling-insulation-incl-remove-existing",
          schedule2_title: "Ceiling insulation incl remove existing",
          price: 0.0,
          cost: 0.0,
          gst: 0.0,
          gstInc: 0.0,
          related_schedule3_id: "insulation",
        },
      ],
    },
  },
  preliminaries: {
    items: [],
    section: {
      id: "preliminaries",
      code: "P",
      order: 1,
      title: "Preliminaries",
      schedule2_title: "Preliminaries",
    },
  },
  "security-doors": {
    items: [],
    section: {
      id: "security-doors",
      code: 204,
      order: 18,
      title: "Security Doors",
      schedule2_title: "Security Doors",
    },
  },
  "floor-coverings": {
    items: [],
    section: {
      id: "floor-coverings",
      code: 104,
      order: 5,
      title: "Floor Coverings",
      schedule2_title: "Floor Coverings",
    },
  },
  "kitchen-fittings": {
    items: [],
    section: {
      id: "kitchen-fittings",
      code: 101,
      order: 2,
      title: "Kitchen Fittings",
      schedule2_title: "Kitchen Fittings and Appliances",
    },
  },
  "laundry-fittings": {
    items: [],
    section: {
      id: "laundry-fittings",
      code: 103,
      order: 4,
      title: "Laundry Fittings",
      schedule2_title: "Laundry Fittings",
    },
  },
  "structural-works": {
    items: [],
    section: {
      id: "structural-works",
      code: 112,
      order: 12,
      title: "Structural Works",
      schedule2_title: "Structural Works",
    },
  },
  "bathroom-fittings": {
    items: [],
    section: {
      id: "bathroom-fittings",
      code: 102,
      order: 3,
      title: "Bathroom Fittings",
      schedule2_title: "Bathroom Fittings",
    },
  },
  "exterior-cladding": {
    items: [],
    section: {
      id: "exterior-cladding",
      code: 203,
      order: 17,
      title: "Exterior Cladding",
      schedule2_title: "Exterior Cladding",
    },
  },
  "plumbing-services": {
    items: [],
    section: {
      id: "plumbing-services",
      code: 106,
      order: 7,
      title: "Plumbing Services",
      schedule2_title: "Plumbing Services",
    },
  },
  "site-improvements": {
    items: [],
    section: {
      id: "site-improvements",
      code: 206,
      order: 20,
      title: "Site improvements",
      schedule2_title: "Site improvements",
    },
  },
  "curtains-and-blinds": {
    items: [],
    section: {
      id: "curtains-and-blinds",
      code: 109,
      order: 10,
      title: "Curtains and blinds",
      schedule2_title: "Blinds and Curtains",
    },
  },
  "other-external-work": {
    items: [],
    section: {
      id: "other-external-work",
      code: 209,
      order: 23,
      title: "Other external work",
      schedule2_title: "Other Exterior Work",
    },
  },
  "other-internal-work": {
    items: [],
    section: {
      id: "other-internal-work",
      code: 114,
      order: 14,
      title: "Other Internal Work",
      schedule2_title: "Other Internal Work",
    },
  },
  "windows-doors-joinery": {
    items: [],
    section: {
      id: "windows-doors-joinery",
      code: 108,
      order: 9,
      title: "Windows/Doors/Joinery",
      schedule2_title: "Windows / Doors / Joinery",
    },
  },
  "asbestos-haz-mat-removal": {
    items: [],
    section: {
      id: "asbestos-haz-mat-removal",
      code: 113,
      order: 13,
      title: "Asbestos/Haz Mat removal",
      schedule2_title: "Asbestos/Hazardous Mat. Removal",
    },
  },
  "disability-modifications": {
    items: [],
    section: {
      id: "disability-modifications",
      code: "S1",
      order: 24,
      title: "Disability Modifications",
      schedule2_title: "Disability Modifications",
    },
  },
  "wall-preparation-painting": {
    items: [],
    section: {
      id: "wall-preparation-painting",
      code: 105,
      order: 6,
      title: "Wall preparation, Painting",
      schedule2_title: "Wall preparation, Painting",
    },
  },
  "ext-asbestos-haz-mat-removal": {
    items: [],
    section: {
      id: "ext-asbestos-haz-mat-removal",
      code: 207,
      order: 21,
      title: "Asbestos/Haz Mat removal",
      schedule2_title: "Asbestos/Hazardous Mat. Removal",
    },
  },
  "outside-installation-and-drainage": {
    items: [],
    section: {
      id: "outside-installation-and-drainage",
      code: 202,
      order: 16,
      title: "Outside installation and drainage",
      schedule2_title: "Outside installations and drainage",
    },
  },
};

// Scope Item unit types
export const scopeItemUnitTypes = [
  {
    value: "no",
    label: "No",
  },
  {
    value: "no.",
    label: "No.",
  },
  {
    value: "set",
    label: "Set",
  },
  {
    value: "sets",
    label: "Sets",
  },
  {
    value: "plans",
    label: "Plans",
  },
  {
    value: "l/m",
    label: "L/M",
  },
  {
    value: "m2",
    label: "M2",
  },
  {
    value: "m3",
    label: "M3",
  },
  {
    value: "item",
    label: "ITEM",
  },
  {
    value: "items",
    label: "ITEMS",
  },
  {
    value: "thru'out",
    label: "Thru'out",
  },
  {
    value: "units",
    label: "UNITS",
  },
];
